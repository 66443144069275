<template>
  <div class="item-operate">
    <!--1：待提货-->
    <div class="btns-box" v-if="item.status === 1">
      <!--默认-->
      <van-count-down
        v-if="item.oid === 0 || !item.trade"
        class="van-ellipsis"
        @finish="autoRecycle(item.oid)"
        :time="item.recycle_remain * 1000"
        format="DD天HH时mm分ss秒后自动回收"
      />
      <!--未付款-->
      <div v-else>
        <!-- <span class="status-text">待付款</span> -->
        <van-button type="default" @click.stop="cancelOrder(item.oid)"
          >取消订单</van-button
        >
        <van-button type="warning" @click.stop="toPay(item)">
          付款
          <van-count-down
            v-if="item.trade"
            @finish="timeOver(item.oid)"
            :time="item.trade.pay_remain * 1000"
            format="mm:ss"
          />
        </van-button>
      </div>
    </div>
    <!--2：待付款-->
    <div
      class="btns-box"
      v-if="
        (item.status === 2 && routename === 'Cabinet') ||
        (item.status === 3 && routename === 'OrderDetail')
      "
    >
      <van-button type="warning" @click.stop="receive(item.oid)"
        >确认收货</van-button
      >
    </div>
    <!--3：待收货-->
    <div
      class="btns-box"
      v-if="
        (item.status === 3 && routename === 'Cabinet') ||
        (item.status === 4 && routename === 'OrderDetail')
      "
    >
      <ServicesBtn />
    </div>
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import ServicesBtn from "@/components/ServicesBtn.vue";
export default {
  name: "CabinetOperate",
  props: {
    item: Object,
  },
  data() {
    return {
      routename: null,
    };
  },
  components: { ServicesBtn },
  created() {
    this.routename = this.$route.name;
    //console.log(this.routename);
  },
  methods: {
    toPay(item) {
      this.$router.push({
        name: "Pay",
        params: { data: item },
      });
    },
    autoRecycle() {
      Bus.$emit("autoRecycleOnbus");
    },
    timeOver(oid) {
      Bus.$emit("timeOverOnbus", oid);
    },
    cancelOrder(oid) {
      this.$dialog
        .confirm({
          title: "还差一步，宝贝就到手啦！",
          message: "确定取消订单吗？",
          closeOnPopstate: false, //点击取消后还能再弹窗
        })
        .then(() => {
          this.$ajax
            .post("/order/order/cancel/", {
              oid: oid,
            })
            .then(() => {
              Bus.$emit("cancelOrderOnbus", oid);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    receive(oid) {
      this.$dialog
        .confirm({
          message: "是否确认收货？",
        })
        .then(() => {
          this.$ajax
            .post("/order/order/confirm/", {
              oid: oid,
            })
            .then(() => {
              Bus.$emit("receiveOnbus", oid);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
