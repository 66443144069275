<template>
  <div class="cabinet">
    <Owned mark="Cabinet" />

    <van-row class="nav" gutter="12">
      <van-col v-for="tab in tabs" :key="tab.id" span="6">
        <div
          class="tab"
          :class="{ active: filter === tab.id }"
          @click="tabChange(tab.id)"
        >
          <span class="count">{{ tab.num }}</span>
          <span class="label">{{ tab.label }}</span>
        </div>
      </van-col>
    </van-row>

    <van-tabs
      class="tabs mbox-tabs"
      type="card"
      v-model="rarityActive"
      @change="rarityChange"
    >
      <van-tab
        v-for="tab in rarityTabs"
        :key="tab"
        :title="tab | grade('款')"
        :name="tab"
      />
      <!-- <van-pull-refresh v-model="pullLoading" @refresh="reload"> -->
      <van-empty v-if="!orders || orders.length < 1" description="暂无数据" />
      <van-list
        v-model="loading"
        :finished="finished"
        @load="getCabinet"
        class="box-list"
        :class="{ 'has-tabbar': filter === 1 }"
      >
        <!--单个订单-->
        <van-checkbox-group
          v-model="selected"
          :checked-color="$global.checkedColor"
        >
          <div
            v-for="item in orders"
            :key="item.cid"
            class="box-card"
            :data-oid="item.oid"
            :data-cid="item.cid"
            :data-iid="item.iid"
            :class="{ recycled: item.status === 4 }"
            @click="viewDetail(item)"
          >
            <div v-if="filter === 1" class="chose">
              <van-checkbox
                :class="{ block: !item.trade }"
                :name="item"
                :disabled="typeof item.trade === 'object' || item.dsbMax20"
                icon-size="16px"
              />
            </div>
            <div class="pic">
              <img :src="item.pic" />
              <span class="lv-badge" :class="'itevel-' + item.rarity">
                {{ item.rarity | grade }}款
              </span>
            </div>
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <div class="price">{{ item.price }}</div>
              <CabinetOperate :item="item" />
            </div>
          </div>
          <!--/单个订单-->
        </van-checkbox-group>
      </van-list>
      <!-- </van-pull-refresh> -->
      <div v-if="filter === 1" class="tip-bar">【免邮】满5个免运费</div>
    </van-tabs>

    <div v-if="filter === 1" class="btns-bar">
      <div class="btns-box">
        <div class="slet-btn">
          已选(<span class="count">{{ selected.length }}</span
          >)
        </div>
        <van-button
          type="warning"
          plain
          round
          :disabled="selected.length === 0"
          text="立即闪出"
          @click="toRecycle()"
        />
        <van-button
          type="info"
          round
          :disabled="selected.length === 0"
          text="立即发货"
          @click="makePrepay()"
        />
      </div>
    </div>

    <!--闪出确认-->
    <van-popup v-model="recyclePop" closeable position="bottom" round>
      <RecycleConfirm :selected="selected" :recycleCost="recycleCost" />
    </van-popup>
    <!--/闪出确认-->
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import Owned from "@/components/Owned.vue";
import CabinetOperate from "@/components/CabinetOperate.vue";
import RecycleConfirm from "@/components/RecycleConfirm.vue";
export default {
  name: "Cabinet",
  components: { Owned, CabinetOperate, RecycleConfirm },
  data() {
    return {
      recyclePop: false,
      orders: null,
      filter: 1, //1：待提货；2：待收货；3：已完成；4：已闪出；
      //tab
      tabs: [
        {
          id: 1,
          num: "",
          label: "待提货",
        },
        {
          id: 2,
          num: "",
          label: "待收货",
        },
        {
          id: 3,
          num: "",
          label: "已完成",
        },
        {
          id: 4,
          num: "",
          label: "已闪出",
        },
      ],
      //sub-tab
      rarityActive: -1,
      rarityTabs: [-1, 0, 1, 2, 3],
      loading: false,
      finished: false,
      pullLoading: false,
      page: 0,
      //闪出金额
      recycleCost: 0,
      //checkbox相关
      checkall: false,
      //选中的
      selected: [],
      //每次最多能选中(闪出)
      selectedMax: 20,
    };
  },
  watch: {
    selected(val) {
      this.orders.forEach((order, i) => {
        let inside = this.inSelected(order);
        if (!inside) {
          let disabled = false;
          if (val.length >= this.selectedMax) disabled = true;
          this.$set(this.orders[i], "dsbMax20", disabled);
        }
      });
    },
  },
  created() {},
  mounted() {
    //取消订单
    Bus.$off("cancelOrderOnbus");
    Bus.$on("cancelOrderOnbus", (oid) => {
      this.cancelOrder(oid);
    });
    //超时未支付
    Bus.$off("timeOverOnbus");
    Bus.$on("timeOverOnbus", (oid) => {
      this.cancelOrder(oid);
    });
    //确认收货
    Bus.$off("receiveOnbus");
    Bus.$on("receiveOnbus", (oid) => {
      this.receive(oid);
    });
    //自动闪出（拉一次）
    Bus.$off("autoRecycleOnbus");
    Bus.$on("autoRecycleOnbus", () => {
      this.reload();
    });
  },
  methods: {
    inSelected(order) {
      let inside = false;
      for (let i = 0; i < this.selected.length; i++) {
        const item = this.selected[i];
        if (order.cid === item.cid) {
          inside = true;
          break;
        }
      }
      return inside;
    },
    reload() {
      this.page = 0;
      this.selected = [];
      this.getCabinet();
    },
    toRecycle() {
      //计算闪出总价
      this.recycleCost = 0;
      this.selected.forEach((item) => {
        this.recycleCost += item.recycle_price * 1;
      });
      this.recycleCost = this.recycleCost.toFixed(2);
      //显示闪出确认框
      this.recyclePop = true;
    },
    makePrepay() {
      let cids = [];
      this.selected.forEach((item) => {
        cids.push(item.cid);
      });
      this.$store.commit("Preids", cids); //Preids是数组
      this.$router.push("/prepay/cabinet"); //cabinet必须，用于prepay识别是商品还是盒柜
    },
    tabChange(tabid) {
      if (this.filter === tabid || this.loading) return false;
      this.orders = [];
      this.page = 0;
      this.filter = tabid;
      this.rarityActive = -1;
      this.selected = [];
      this.getCabinet();
    },
    rarityChange(rarity) {
      this.orders = [];
      this.page = 0;
      this.rarityActive = rarity;
      this.selected = [];
      this.getCabinet();
    },
    getCabinet() {
      this.$ajax
        .post("/user/cabinet/index/", {
          token: 1,
          filter: this.filter,
          rarity: this.rarityActive,
          page_no: this.page,
          page_size: 100,
        })
        .then((res) => {
          this.$store.commit("Coin", res.coin);
          //盒柜里的数量，从1开始，因为没有-1（全部）
          for (let i = 0; i < this.tabs.length; i++) {
            this.tabs[i].num = res.cabinet_num[i + 1];
          }
          if (this.page === 0) {
            this.orders = res.data;
          } else {
            if (res.data) {
              this.orders = [...this.orders, ...res.data];
            }
          }

          if (res.next_page < 0) {
            this.finished = true;
          } else {
            this.page = res.next_page;
            this.finished = false;
          }
          this.loading = false;
          this.pullLoading = false;
        });
    },
    //取消订单，订单超时付款关闭
    cancelOrder(oid) {
      for (let i = 0; i < this.orders.length; i++) {
        const order = this.orders[i];
        if (oid === order.oid) {
          this.$set(order, "trade", null);
          this.$set(order, "oid", 0);
          break;
        }
      }
    },
    //确认收货
    receive(oid) {
      // for (let i = this.orders.length - 1; i >= 0; i--) {
      //   if (oid === this.orders[i].oid) {
      //     this.orders.splice(i, 1);
      //   }
      // }
      this.filter = 3;
      this.reload();
    },
    //查看订单详细
    viewDetail(item) {
      if (item.status === 1 || item.status === 4) return false;
      this.$router.push({
        name: "OrderDetail",
        params: { oid: item.oid, type: 3 },
      });
    },
  },
};
</script>
