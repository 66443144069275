<template>
  <div class="owned">
    <div class="inner">
      我拥有的
      <div class="owned-coin"><i class="icon-coin" /> {{ Coin }}</div>
      可抵扣金额：¥{{ Coin / 100 }}
    </div>
    <van-button
      type="danger"
      size="small"
      round
      :text="text"
      @click="toRouter()"
    />
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    mark: String,
  },
  data() {
    return {
      text: null,
      route: null,
    };
  },
  computed: {
    Coin() {
      return this.$store.state.Coin;
    },
  },
  created() {
    if (this.mark === "Cabinet") {
      this.text = "交易记录";
      this.route = "Record";
    } else {
      this.text = "福袋订单";
      this.route = "Cabinet";
    }
  },
  methods: {
    toRouter() {
      this.$router.push({ name: this.route });
    },
  },
};
</script>
