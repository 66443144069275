<template>
  <div>
    <van-cell-group class="popup-recycle cell-nowrap">
      <van-cell class="closebtn-placeholder" />
      <van-cell title="闪出金额">
        <template #default>
          <!-- <span class="labelled">闪出80%</span> -->
          <span class="num"> ￥{{ recycleCost }} </span>
        </template>
      </van-cell>
      <van-cell title="件数">
        <template #default>
          <span class="num">{{ selected.length }}</span> 件
        </template>
      </van-cell>
      <van-cell title="合计">
        <template #default>
          <i class="icon-coin" />
          <span class="num">{{ Math.round(recycleCost * 0.8 * 100) }}</span>
        </template>
      </van-cell>

      <van-cell class="cell-agree" :border="false">
        <template #title>
          <span class="buytip">
            闪出即视为拒绝提货，将商品转换为魔晶，<br />魔晶可抵扣购买福袋的费用
          </span>
        </template>
      </van-cell>
    </van-cell-group>
    <van-row class="popup-recycle-end" gutter="20">
      <van-col span="12">
        <van-button
          type="warning"
          round
          block
          plain
          text="取消"
          @click="close()"
        />
      </van-col>
      <van-col span="12">
        <van-button
          type="info"
          round
          block
          @click="recycled()"
          text="确认闪出"
        />
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    selected: Array,
    recycleCost: String,
  },
  data() {
    return {};
  },
  computed: {
    BoxBadge() {
      return this.$store.state.BoxBadge;
    },
  },
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$parent.$parent.recyclePop = false;
    },
    recycled() {
      let cids = [];
      this.selected.forEach((item) => {
        cids.push(item.cid);
      });
      this.$ajax
        .post("/user/cabinet/recycle/", { cids: cids.join() }, "loading")
        .then((res) => {
          this.close();
          this.$store.commit("Coin", res.coin);
          this.$store.commit("BoxBadge", this.BoxBadge - this.selected.length);
          this.$parent.$parent.reload();
        });
    },
  },
};
</script>
